var wwCheck = 0;
var counter = 1;

function windowWidthDependent() {
	wwCheck = 1;
	$( '.footer-contacts__socials' ).insertBefore( $( '.wss' ) );

	$( 'body' ).on( 'click', '.sidebar__title', function() {
		$( '.sidebar .accordion' ).slideToggle();
		$( this ).toggleClass( 'active' );
	});
	$( 'body' ).on( 'click', '.has-child', function() {
		$( this ).find( '.sub-menu' ).slideToggle();
		$( this ).toggleClass( 'active' );
	});
}

function imgFlex( elem ) {
	var float = 'img[style="float: left"]';
	var className = 'inline-img-' + counter;
	var next = elem.next();
	elem.addClass( className );
	if ( next.length > 0 ) {
		if ( next[ 0 ].nodeName === 'IMG' ) {
			imgFlex( next );
		} else {
			$( '.' + className ).wrapAll( '<div class="inline-img-wrap"></div>' );
			var wrap = $( '.inline-img-wrap' );
			counter = counter + 1;
			if ( wrap.last().nextAll( float ).first().length > 0 ) {
				imgFlex( wrap.nextAll( float ).first() );
			}
		}
	} else {
		$( '.' + className ).wrapAll( '<div class="inline-img-wrap"></div>' );
	}
}

$( document ).ready( function() {
	if ( $( 'img[style="float: left"]' ).first().length > 0 ) {
		imgFlex( $( 'img[style="float: left"]' ).first() );
	};

	$( 'input[value]' ).addClass( 'filled' );

	if ( $( window ).width() < 767 && wwCheck === 0 ) {
		windowWidthDependent();
	}

	$( '.product__images' ).slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		fade: true,
		asNavFor: '.product__nav'
	});

	$( '.product__nav' ).slick({
		slidesToShow: 5,
		prevArrow: '<span class="prev-arrow"></span>',
		nextArrow: '<span class="next-arrow"></span>',
		slidesToScroll: 1,
		asNavFor: '.product__images',
		dots: false,
		centerMode: false,
		focusOnSelect: true
	});

	if ( $( '#mapid' ).length > 0 ) {
		var mymap = L.map( 'mapid' ).setView( [ latMap, lngMap ], zoomMap );

		L.tileLayer( 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
			attribution: ''
		}).addTo( mymap );

		var marker = L.marker( [ latMap, lngMap ] ).addTo(mymap);
	}
	
	var fix = $( '.fix-scroll' );

	if ( fix.length > 0 && $( window ).width() > 767 && $( '.fix-scroll' ).height() < $( 'main' ).height() ) {
		console.log($('.fix-scroll').height());
		console.log($('main').height());
		var start = fix.offset().top
		$( window ).on( 'scroll', function() {
			if ( ( $( '.wrap' ).height() - ( fix.offset().top + fix.height() ) ) <= 40 ) {
				fix.attr( 'style', 'position: absolute; top: auto; bottom: 0px;' );
			}
			if ( $( window ).scrollTop() - fix.offset().top < -69 ) {
				fix.removeAttr( 'style' );
			}
			if ( ( fix.offset().top - $( window ).scrollTop() ) <= 70 && fix.offset().top >= start ) {
				fix.addClass( 'fixed' );
			} else {
				fix.removeClass( 'fixed' );
			}
		});
	}
});

$( window ).resize( function() {
	if ( $( window ).width() < 767 && wwCheck === 0 ) {
		windowWidthDependent();
	}
});

$( window ).on( 'scroll', function() {
	if ( $( window ).scrollTop() > $( '.header' ).height() ) {
		$( 'body' ).addClass( 'scrolled' );
	} else {
		$( 'body' ).removeClass( 'scrolled' );
	}
});

$( 'body' ).on( 'click', '.js-accordion__title span:not(.active)', function() {
	$( this ).parent().parent().find( '.js-accordion__sub-items' ).slideDown().addClass( 'active' );
	$( this ).parent().parent().siblings().find( '.js-accordion__sub-items' ).slideUp().removeClass( 'active' );
	$( this ).parent().parent().siblings().find( '.js-accordion__title span' ).removeClass( 'active' );
	$( this ).parent().parent().siblings().find( '.js-accordion__title' ).removeClass( 'active' );
	$( this ).parent().addClass( 'active' );
	$( this ).addClass( 'active' );
});

$( 'body' ).on( 'click', '.js-accordion__title span.active', function() {
	$( this ).parent().parent().find( '.js-accordion__sub-items' ).slideUp().removeClass( 'active' );
	$( this ).parent().removeClass( 'active' );
	$( this ).removeClass( 'active' );
});

$( 'body' ).on( 'click', '.callback-btn', function() {
	$( '.callback' ).addClass( 'active' );
});

$( 'body' ).on( 'click', '.callback__close', function() {
	$( '.callback' ).removeClass( 'active' );
});

$( 'body' ).on( 'click', '.nav-toggle', function() {
	$( this ).toggleClass( 'active' );
	$( '.nav-search' ).toggleClass( 'active' );
	$( '.nav-search__nav' ).slideToggle().toggleClass( 'active' );
	$( '.header__language' ).toggleClass( 'active' );
});

$( 'body' ).on( 'keyup', 'input, textarea', function() {
	if ( $( this ).val().length > 0 ) {
		$( this ).addClass( 'filled' );
	} else {
		$( this ).removeClass( 'filled' );
	}
});

$( 'body' ).on( 'click', '.tabs__nav > *', function() {
	$( this ).addClass( 'active' ).siblings().removeClass( 'active' );
	$( this ).parent().siblings().find( ' > *' ).eq( $( this ).index() ).addClass( 'active' ).siblings().removeClass( 'active' );
});

$( '.js-categories__item' ).on( 'mouseenter mouseleave', function() {
	$( this ).find( '.js-categories__list' ).slideToggle( 'fast' );
});

$( 'input[type="tel"]' ).keydown( function ( e ) {
	if ( $.inArray( e.keyCode, [ 173, 107, 109, 46, 8, 9, 27, 13, 110, 32, 190, 189 ] ) !== -1 ||// Allow: backspace, delete, tab, escape, enter, space, and .
		e.keyCode === 65 && e.ctrlKey ||// Allow: Ctrl+A
		e.keyCode === 67 && e.ctrlKey ||// Allow: Ctrl+C
		e.keyCode === 88 && e.ctrlKey ||// Allow: Ctrl+X
		e.keyCode === 86 && e.ctrlKey ||// Allow: Ctrl+V
		e.keyCode === 187 && e.shiftKey ||// Allow: + with shift
		e.keyCode === 57 && e.shiftKey ||// Allow: ( with shift
		e.keyCode === 48 && e.shiftKey ||// Allow: ) with shift
		e.keyCode >= 35 && e.keyCode <= 39 ) {// Allow: home, end, left, right
		return;   // let it happen, don't do anything
	}// Ensure that it is a number and stop the keypress
	if ( ( e.shiftKey || e.keyCode < 48 || e.keyCode > 57 ) && ( e.keyCode < 96 || e.keyCode > 105 ) ) {
		e.preventDefault();
	}
});
